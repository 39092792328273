<template>
<div v-if="data">
            <div v-if="data.sent_to_vendor.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">Send To Vendor</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Purchase Order ID</div>
                    <div v-if="data.sent_to_vendor.id" class="col-2 p-1 b-1 text-muted">{{
                            data.sent_to_vendor.id
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Created By</div>
                    <div v-if="data.sent_to_vendor.created_by" class="col-2 p-1 b-1 text-muted">{{
                            data.sent_to_vendor.created_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Date</div>
                    <div v-if="data.sent_to_vendor.date" class="col-2 p-1 b-1 text-muted">{{
                            data.sent_to_vendor.date
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
                <div class="row mt-2 pl-3 pt-3 pr-5">
                    <div class="col-2 label">Remarks</div>
                    <div v-if="data.sent_to_vendor.remarks" class="col p-1 b-1 text-muted">
                        {{ data.sent_to_vendor.remarks }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
            <div v-if="data.finance_rejected.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">Finance Rejected</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Purchase Order ID</div>
                    <div v-if="data.finance_rejected.id" class="col-2 p-1 b-1 text-muted">{{
                            data.finance_rejected.id
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Rejected By</div>
                    <div v-if="data.finance_rejected.created_by" class="col-2 p-1 b-1 text-muted">{{
                            data.finance_rejected.created_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Date</div>
                    <div v-if="data.finance_rejected.date" class="col-2 p-1 b-1 text-muted">{{
                            data.finance_rejected.date
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
                <div class="row mt-2 pl-3 pt-3 pr-5">
                    <div class="col-2 label">Remarks</div>
                    <div v-if="data.finance_rejected.remarks" class="col p-1 b-1 text-muted">
                        {{ data.finance_rejected.remarks }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
            <div v-if="data.finance.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">FA Approved</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Purchase Order ID</div>
                    <div v-if="data.finance.id" class="col-2 p-1 b-1 text-muted">{{ data.finance.id }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Approved By</div>
                    <div v-if="data.finance.approved_by" class="col-2 p-1 b-1 text-muted">{{
                            data.finance.approved_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Date</div>
                    <div v-if="data.finance.date" class="col-2 p-1 b-1 text-muted">{{ data.finance.date }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
                <div class="row mt-2 pl-3 pt-3 pr-5">
                    <div class="col-2 label">Remarks</div>
                    <div v-if="data.finance.remarks" class="col p-1 b-1 text-muted">{{ data.finance.remarks }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
            <div v-if="data.purchase.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">PA Approved</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Purchase Order ID</div>
                    <div v-if="data.purchase.id" class="col-2 p-1 b-1 text-muted">{{ data.purchase.id }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Approved By</div>
                    <div v-if="data.purchase.approved_by" class="col-2 p-1 b-1 text-muted">{{
                            data.purchase.approved_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Date</div>
                    <div v-if="data.purchase.date" class="col-2 p-1 b-1 text-muted">{{ data.purchase.date }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
                <div class="row mt-2 pl-3 pt-3 pr-5">
                    <div class="col-2 label">Remarks</div>
                    <div v-if="data.purchase.remarks" class="col p-1 b-1 text-muted">{{ data.purchase.remarks }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
            <div v-if="data.po_generated.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">PO Generated</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Purchase Order ID</div>
                    <div v-if="data.po_generated.id" class="col-2 p-1 b-1 text-muted">{{ data.po_generated.id }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Created By</div>
                    <div v-if="data.po_generated.approved_by" class="col-2 p-1 b-1 text-muted">{{
                            data.po_generated.approved_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Date</div>
                    <div v-if="data.po_generated.date" class="col-2 p-1 b-1 text-muted">{{
                            data.po_generated.date
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
            <div v-if="data.request.id" class="card mt-2  mb-3 pl-0 pr-0 pt-0 b-1">
                <h5 class="p-3 bb-2 head-color">Created</h5>
                <div class="row pl-3 pt-3 pr-5">
                    <div class="col-2 label">Request ID</div>
                    <div v-if="data.request.id" class="col-2 p-1 b-1 text-muted">{{ data.request.id }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Approved By</div>
                    <div v-if="data.request.approved_by" class="col-2 p-1 b-1 text-muted">{{
                            data.request.approved_by
                        }}
                    </div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                    <div class="col-2 label">Date</div>
                    <div v-if="data.request.date" class="col-2 p-1 b-1 text-muted">{{ data.request.date }}</div>
                    <div v-else class="col-2 p-1 b-1 text-muted">Nil</div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name  : 'HistoryPage',
    props : {
        data : { type : Array }
    }
};
</script>

<style scoped>
.b-1 {
    border-radius: 4px;
}

.head-color {
    background-color: #eeeeeea8;
}

.label {
    font-weight: 500;
}
</style>
